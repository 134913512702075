import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, sortBy, isEqual, orderBy } from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { SingleDatePicker } from '../DatePicker';
import DropDown from '../DropDown';
import {
  getHours,
  convertTimeTo12HrFormat,
  displayToast,
  getTechniciansName,
  checkPermission,
} from '../../_helpers/commonFunctions';
import CustomMultiSelectDropDown from '../CustomMultiSelectDropdown';
import Button from '../Button';
import getJobListAction, {
  assignJobAction,
  unassignJobAction,
  deleteJobAction,
  getMissedJobListAction,
  getTeamListWithFiltersAction,
  getNextMonthJobListAction,
} from '../../redux/actions/jobLinkActions';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import { subPermissions, permissions } from '../../libs/constants/permissions';
import { durationTypes } from '../../utils/Constants';
import TaskModalBody from '../../layouts/CustomerHomeNewLayout/taskModalBody';
import ToolTip from '../ToolTip';
import {
  runCoachMarks,
  toggleCoachmarksVisibility,
} from '../../redux/actions/signUp';
import coachMarks from '../../libs/constants/coachMarks';
import NotesBodys from './NotesBody';

let page = 1;
const limit = 100;

export class EditJobModalBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventStartDate: Moment(),
      eventStartTime: null,
      eventEndDate: Moment(),
      eventEndTime: null,
      duration: '0',
      hours: '',
      minutes: '',
      timeFormat: 'AM',
      selectedTeamNames: {},
      teams: [],
      technicians: [],
      noteId: '',
      note: {},
      noteAcc: false,
      errors: {
        duration: '',
        technician: '',
        endDate: '',
      },
      isDeleteModal: false,
      durationBy: durationTypes[0],
      teamsWithId: {},
      saved: false,
      durationSet: false,
      showModal: true,
    };
  }

  setInitialState = () => {
    const { techniciansAssigned, currentEvent, jobDetail } = this.props;
    const { duration, technicians, durationBy } = jobDetail;
    const { start, end } = currentEvent;
    const initialTechs = this.setInitialTechnicians(technicians);
    const initialNoteDetails = this.getInitialNoteDetails();
    const startTime = new Date(this.props.currentEvent.start).toTimeString();
    const time = this.props.currentEvent.start
      ? convertTimeTo12HrFormat(startTime.split(' ')[0])
      : {
          hours: '1',
          minutes: '30',
          am_pm: 'AM',
        };
    const eventStartDate = Moment(start.split(' ')[0]);
    const initialState = {
      eventStartDate,
      eventStartTime: duration ? Moment(start).toISOString() : start,
      eventEndDate:
        durationBy && durationBy === durationTypes[1]
          ? Moment(end).endOf('day')
          : duration
          ? Moment(eventStartDate)
              .clone()
              .startOf('day')
              .add(time.hours, 'hours')
              .add(time.minutes, 'minutes')
              .add(duration, 'hours')
          : Moment(end),
      eventEndTime: duration
        ? Moment(start).add(duration, 'hours').toISOString()
        : end,
      teams: techniciansAssigned,
      noteId: initialNoteDetails.noteId,
      note: { ...initialNoteDetails.note },
      selectedTeamNames: initialTechs.initialTeams,
      technicians: initialTechs.initialTechs,
      teamsWithId: initialTechs.initialTeamsWithId,
      duration: duration || '0',
      hours: time.hours,
      minutes: time.minutes,
      timeFormat: time.am_pm,
      durationBy: durationBy || durationTypes[0],
    };
    this.setState({
      ...initialState,
    });
  };

  getInitialNoteDetails() {
    const { jobDetail } = this.props;
    let noteId = '';
    const note = {
      customerNotes: [],
      inspectorNotes: [],
    };
    if (jobDetail.noteId) {
      noteId = jobDetail.noteId._id;
      note.inspectorNotes = [...jobDetail.noteId.inspectorNotes];
      note.customerNotes = [...jobDetail.noteId.customerNotes];
    }
    return {
      noteId,
      note,
    };
  }

  setInitialTechnicians = (technicians) => {
    let initialTechs = [];
    initialTechs =
      technicians &&
      technicians.map((tech) => {
        const techClone = { ...tech };
        delete techClone._id;
        return techClone;
      });
    const initialTeams = {};
    if (technicians) {
      technicians.forEach((tech) => {
        initialTeams[tech.customerId] = true;
      });
    }
    const initialTeamsWithId = {};
    if (this.props.techniciansAssigned) {
      this.props.techniciansAssigned.forEach((tech) => {
        initialTeamsWithId[tech.id] = tech.name;
      });
    }
    return {
      initialTechs,
      initialTeams,
      initialTeamsWithId,
    };
  };

  setTechnicians = (name, value, isDefaultTechnician = false) => {
    const technician = {};
    const technicians = this.state.technicians
      ? [...this.state.technicians]
      : [];
    const { techniciansAssigned } = this.props;
    if (value) {
      technician.customerId = name;
      technician.isDefault = isDefaultTechnician || false;
      techniciansAssigned.forEach((tech) => {
        if (tech.id === name) {
          technician.customerName = tech.name;
        }
      });
      const techIds = technicians.map((tech) => tech.customerId);
      if (!techIds.includes(technician.customerId)) {
        technicians.push(technician);
      } else {
        technicians[techIds.indexOf(technician.customerId)].isDefault =
          isDefaultTechnician;
      }
    } else {
      technicians.forEach((tech, index) => {
        if (tech.customerId === name) {
          technicians.splice(index, 1);
        }
      });
    }
    this.setState({ technicians }, () => {
      if (this.state.errors.technician) this.setErrors();
    });
  };

  handleTeamSelection = (name, value) => {
    const selectedTeamNames = { ...this.state.selectedTeamNames };
    let teams = [...this.state.teams];
    this.setTechnicians(name, value);
    if (!value) {
      delete selectedTeamNames[`${name}`];
      this.setState({ selectedTeamNames }, () => {
        teams = Object.keys(this.state.selectedTeamNames);
      });
    } else {
      selectedTeamNames[`${name}`] = value;
      this.setState({ selectedTeamNames }, () => {
        teams = Object.keys(this.state.selectedTeamNames);
      });
    }
    this.setState({ teams });
  };

  noteAccordian = () => {
    this.setState({
      noteAcc: !this.state.noteAcc,
    });
  };

  getNewEndDate = (
    newStartDate,
    newDuration,
    startHours,
    startMinutes,
    newTimeFormat
  ) => {
    let newHours = startHours || this.state.hours;
    const newMinutes = startMinutes || this.state.minutes;
    const timeFormat = newTimeFormat || this.state.timeFormat;
    let newEndDate = this.state.eventStartDate.clone();
    let hoursToAdd = this.state.duration;
    if (newStartDate) {
      newEndDate = newStartDate.clone();
    }
    if (newDuration) {
      hoursToAdd = newDuration;
    }
    newHours = Number(newHours);
    if (timeFormat === 'PM' && newHours !== 12) {
      newHours += 12;
    }
    if (timeFormat === 'AM' && newHours === 12) {
      newHours = 0;
    }

    newEndDate
      .startOf('day')
      .add(newHours, 'hours')
      .add(newMinutes, 'minutes')
      .add(Number(hoursToAdd), 'hours');
    return newEndDate.clone();
  };

  handleTimeSelection = (name, value) => {
    const tempState = { ...this.state };
    tempState[name] = value;
    if (name === 'duration') {
      tempState.eventEndDate = this.getNewEndDate(undefined, value);
    } else if (name === 'hours') {
      tempState.eventEndDate = this.getNewEndDate(undefined, undefined, value);
    } else if (name === 'minutes') {
      tempState.eventEndDate = this.getNewEndDate(
        undefined,
        undefined,
        undefined,
        value
      );
    } else if (name === 'timeFormat') {
      tempState.eventEndDate = this.getNewEndDate(
        undefined,
        undefined,
        undefined,
        undefined,
        value
      );
    }
    this.setState({ ...tempState });
  };

  getDefaultTechnician = (techName) => {
    const { technicians } = this.state;
    let isDefaultTech = false;
    const filteredTechs =
      technicians && technicians.filter((tech) => tech.isDefault);
    const defaultTechnicians =
      filteredTechs &&
      filteredTechs.map((tech) => {
        if (tech.customerId === techName) {
          isDefaultTech = true;
        }
        return tech.customerName;
      });
    return {
      isDefaultTech,
      defaultTechnicians,
    };
  };

  customiseAssignedJobDetails = (assignedJobDetails) => {
    const { note, noteId } = this.state;
    const { customerNotes, inspectorNotes } = note;
    let customisations = {
      ...assignedJobDetails,
      note: { customerNotes: [], inspectorNotes: [] },
    };
    if (customerNotes.length) {
      customisations = {
        ...customisations,
        note: {
          ...customisations.note,
          customerNotes: customerNotes.filter((cnote) => cnote !== ''),
        },
      };
    }
    if (inspectorNotes.length) {
      customisations = {
        ...customisations,
        note: {
          ...customisations.note,
          inspectorNotes: inspectorNotes.filter((inote) => inote !== ''),
        },
      };
    }
    if (noteId) customisations.noteId = noteId;
    return customisations;
  };

  handleAddNotes = (type, notes) => {
    if (type === 'inspector') {
      this.setState({ note: { ...this.state.note, inspectorNotes: notes } });
    } else if (type === 'customer') {
      this.setState({ note: { ...this.state.note, customerNotes: notes } });
    }
  };

  // checkDurationValidity = () => {
  //   const {
  //     eventStartDate,
  //     eventStartTime,
  //     duration,
  //   } = this.state;
  //   const jobStartTime = Moment(eventStartTime);
  //   const jobEndTime = jobStartTime.add(duration, 'hours');
  //   const dayEndTime = Moment(eventStartDate).endOf('day');
  //   const remainingMinutesInDay = dayEndTime.diff(jobStartTime, 'minutes');
  //   const totalJobMinutes = jobEndTime.diff(jobStartTime, 'minutes');
  //   return (remainingMinutesInDay >= totalJobMinutes);
  // }

  setErrors = () => {
    const { duration, technicians, eventEndDate, eventStartDate, durationBy } =
      this.state;
    const errors = {
      duration: '',
      technician: '',
      endDate: '',
    };
    const isEndDateValid =
      new Date(Moment(eventEndDate).clone().startOf('day').toDate()).getTime() <
      new Date(
        Moment(eventStartDate).clone().startOf('day').toDate()
      ).getTime();
    let isError = false;
    if (
      durationBy !== durationTypes[1] &&
      (duration === 0 || duration === '0')
    ) {
      errors.duration = 'Please enter a valid duration';
      isError = true;
    } else if (isEndDateValid) {
      errors.endDate = 'Please select a valid end date';
      isError = true;
    } else if (!technicians.length) {
      errors.technician = 'Please select a technician';
      isError = true;
    }
    this.setState({ errors });
    return isError;
  };

  getStartAndEndDateTime = () => {
    const {
      eventStartDate,
      eventEndDate,
      hours,
      minutes,
      timeFormat,
      durationBy,
      duration,
    } = this.state;
    let startHours =
      timeFormat === 'PM' && hours !== '12'
        ? Number(hours) + 12
        : Number(hours);
    startHours = timeFormat === 'AM' && hours === '12' ? 0 : startHours;
    const startDateTime = eventStartDate
      .clone()
      .startOf('day')
      .add(startHours, 'hours')
      .add(minutes, 'minutes');
    const endDateTime = eventStartDate
      .clone()
      .startOf('day')
      .add(startHours, 'hours')
      .add(duration, 'hours')
      .add(minutes, 'minutes');
    return {
      startDateTime: Moment(startDateTime.toISOString()).valueOf(),
      endDateTime:
        durationBy === durationTypes[1]
          ? Moment(eventEndDate.clone().endOf('day').toISOString()).valueOf()
          : Moment(endDateTime.toISOString()).valueOf(),
    };
  };

  handleSaveButton = () => {
    const { originalJobId } = this.props;
    const { technicians, duration, durationBy } = this.state;
    const startAndEndDate = this.getStartAndEndDateTime();
    let assignJobDetails = {
      technicians,
      duration: Number(duration),
      startDateTime: startAndEndDate.startDateTime,
      endDateTime: startAndEndDate.endDateTime,
      durationBy,
    };
    assignJobDetails = this.customiseAssignedJobDetails(assignJobDetails);
    if (!this.setErrors()) {
      this.props.assignJobAction(originalJobId, assignJobDetails);
    }
  };

  getResources = () => {
    const { resourceReportCategory, calenderViewData } = this.props;
    const momentStartDate = Moment(calenderViewData.startDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const momentEndDate = Moment(calenderViewData.endDate).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    const queryParams = {
      startDateTime: calenderViewData.startDate
        ? momentStartDate.valueOf()
        : new Date().setHours(0, 0, 0, 0),
      endDateTime: calenderViewData.endDate
        ? momentEndDate.valueOf()
        : new Date().setHours(23, 59, 59, 999),
    };
    if (resourceReportCategory)
      queryParams.reportCategories = resourceReportCategory;
    this.props.getTeamListWithFiltersAction(queryParams, page, 20);
  };

  showToast = (toastMessage, isJobAssigned) => {
    if (isJobAssigned) {
      page = 1;
    }
    displayToast(toastMessage, 'success');
    this.getResources();
    this.props.getJobListAction({}, page, limit);
    this.props.getMissedJobListAction({}, page, limit);
    this.props.getNextMonthJobListAction({}, page, limit);
    this.props.closeModal(false);
  };

  componentDidUpdate(prevProps) {
    const { unassignedJob, deletedJob, assignedJob, jobDetailIsSuccess } =
      this.props;
    const { techniciansAssigned } = this.props;
    const hasTechnicians = techniciansAssigned != null && techniciansAssigned != [] && techniciansAssigned.length > 0;
    if (this.props.currentEvent.save && hasTechnicians) {
      if (this.props.currentEvent.isNew && !this.state.durationSet) {
        this.handleTimeSelection('duration', 1);
        this.handleTeamSelection(this.props.currentEvent.resourceId, true);
        this.setState({ durationSet: true , showModal: false });
      } else if (!this.state.saved) {
        this.handleSaveButton();
        this.setState({ saved: true, showModal: false });
      }
    }
    if (this.props.currentEvent.save && !hasTechnicians && this.state.showModal) this.setState({ showModal: false }); 
    if (this.props.currentEvent.duration && !this.state.durationSet) {
      this.handleTimeSelection('duration', this.props.currentEvent.duration);
      this.setState({ durationSet: true, showModal: false });
    }
    const areTeamsEqual = isEqual(
      sortBy(prevProps.teams),
      sortBy(this.props.teams)
    );
    if (!areTeamsEqual) {
      this.setState({
        teams: [...this.props.teams],
      });
    }
    if (
      !jobDetailIsSuccess &&
      jobDetailIsSuccess !== prevProps.jobDetailIsSuccess
    ) {
      const initialTechs = this.setInitialTechnicians(
        this.props.jobDetail.technicians
      );
      this.setState({
        teamsWithId: initialTechs.initialTeamsWithId,
      });
    }
    if (
      assignedJob.isSuccess &&
      assignedJob.isSuccess !== prevProps.assignedJob.isSuccess
    ) {
      this.showToast(assignedJob.success, true);
    } else if (
      assignedJob.error &&
      prevProps.assignedJob.error !== assignedJob.error
    ) {
      displayToast(assignedJob.error, 'error');
    }
    if (
      !isEmpty(unassignedJob) &&
      unassignedJob.isSuccess &&
      prevProps.unassignedJob.isSuccess !== unassignedJob.isSuccess
    ) {
      this.showToast('Job unassigned successfully');
    } else if (
      !isEmpty(deletedJob) &&
      deletedJob.isSuccess &&
      prevProps.deletedJob.isSuccess !== deletedJob.isSuccess
    ) {
      this.showToast('Job deleted successfully');
    } else if (
      (!isEmpty(deletedJob) &&
        deletedJob.error &&
        prevProps.deletedJob.error !== deletedJob.error) ||
      (!isEmpty(unassignedJob) &&
        unassignedJob.error &&
        prevProps.unassignedJob.error !== unassignedJob.error)
    ) {
      displayToast(deletedJob.error || unassignedJob.error, 'error');
    }
  }

  handleRedirectionToMaps = () => {
    const { clientData } = this.props;
    const { formattedAddress } = clientData;
    window.open(
      `https://www.google.com/maps/search/${formattedAddress.replace('/', '-')}`
    );
  };

  getTotalNotes = () => {
    const { inspectorNotes, customerNotes } = this.state.note;
    let totalNotes = 0;
    const inotes =
      inspectorNotes && inspectorNotes.filter((note) => note !== '');
    const cnotes = customerNotes && customerNotes.filter((note) => note !== '');
    const isInspectorNotes = inotes && inotes.length;
    const isCustomerNotes = cnotes && cnotes.length;
    if (isInspectorNotes) {
      totalNotes = inotes.length;
    }
    if (isCustomerNotes) {
      totalNotes = cnotes.length;
    }
    if (isInspectorNotes && isCustomerNotes) {
      totalNotes = inotes.length + cnotes.length;
    }
    return totalNotes;
  };

  handleChangeDurationBy = (name, value) => {
    let { duration } = this.state;
    let newHours = this.state.hours;
    newHours = Number(newHours);
    const { eventStartDate, timeFormat, minutes } = this.state;
    let newEndDate = this.state.eventEndDate.clone();
    if (value === durationTypes[1]) {
      duration = '0';
      newEndDate = newEndDate.endOf('day');
    } else {
      if (timeFormat === 'PM' && newHours !== 12) {
        newHours += 12;
      }
      if (timeFormat === 'AM' && newHours === 12) {
        newHours = 0;
      }
      newEndDate = eventStartDate
        .clone()
        .startOf('day')
        .add(newHours, 'hours')
        .add(Number(minutes), 'minutes')
        .add(duration, 'hours');
    }
    this.setState({ durationBy: value, duration, eventEndDate: newEndDate });
  };

  componentDidMount() {
    this.setInitialState();
    this.props.runCoachMarks(true);
  }

  componentWillUnmount() {
    this.props.runCoachMarks(true);
  }

  render() {
    const {
      jobId,
      clientData,
      techniciansAssigned,
      originalJobId,
      jobDetail,
      intl,
      auth,
    } = this.props;

    const {
      eventStartDate,
      eventEndDate,
      hours,
      minutes,
      timeFormat,
      selectedTeamNames,
      duration,
      noteAcc,
      note,
      errors,
      isDeleteModal,
      durationBy,
    } = this.state;

    const { inspectorNotes, customerNotes } = note;

    const { defaultTechnicians } = this.getDefaultTechnician();

    const totalNotes = this.getTotalNotes();

    const isMyJobs = checkPermission(
      subPermissions.scheduleMyJobs,
      permissions.jobLink
    );
    const isOthersJobs = checkPermission(
      subPermissions.scheduleOthersJobs,
      permissions.jobLink
    );
    const teamListByPermission =
      isMyJobs && !isOthersJobs
        ? techniciansAssigned &&
          techniciansAssigned.filter((team) => team.id === auth.login.user._id)
        : !isMyJobs && isOthersJobs
        ? techniciansAssigned
        : !isMyJobs && !isOthersJobs
        ? []
        : techniciansAssigned;
    if (!this.state.showModal) return (<></>);
    const clientAddress = clientData.address || {};
    const address = `${clientAddress['Address 1']}, ${clientAddress['Address 2'] || ''}${clientAddress['Address 2'] ? ',' : ''}`;
    const cityStateZip = `${clientAddress.localityname}, ${clientAddress.administrativearea}, ${clientAddress.postalcode}`;
    return (
      <React.Fragment>
        {isDeleteModal && (
          <DeleteConfirmationModal
            handleClickCancel={() => this.setState({ isDeleteModal: false })}
            handleClickCross={() => this.setState({ isDeleteModal: false })}
            handleClickDelete={() => {
              this.props.deleteJobAction(originalJobId);
              this.setState({ isDeleteModal: false });
            }}
            deleteMessage="Are you sure you want to delete this job?"
          />
        )}
        <span
          className="quarterly-wrap-modal"
          onClick={() => {
            if (
              this.props.currentCoachmarkTarget ===
              coachMarks.inspectionReminders.editJob.target
            ) {
              this.props.toggleCoachmarksVisibility(
                null,
                null,
                coachMarks.inspectionReminders.editJob.nextTarget
              );
            }
          }}
        >
          <div className="quaterly-wrap-modal">
            <div className=" quaterly-left">
              <div className="user-address-fld-inn">
                <div className="user-icon1 ofc-no sprite-icon-before">
                  <p>
                    {intl.formatMessage({ id: 'Contact Name' })}:
                    <span>
                      {clientData.contactName ? clientData.contactName : 'NA'}
                    </span>
                  </p>
                </div>
                <div className="ofc-no" id="job-link-modal-job-id">
                  <p>
                    {intl.formatMessage({ id: 'Job Id Number' })}:
                    <span>{jobId}</span>
                  </p>
                </div>
                <div className="ofc-addr sprite-icon-before">
                  <p
                    className="sprite-icon-before email-id-icon"
                    onClick={() =>
                      window.open(
                        'https://mail.google.com/mail/u/0/#inbox?compose=new'
                      )
                    }
                  >
                    {intl.formatMessage({ id: 'Email Id' })}:
                    <span
                      id="joblink-email"
                      data-tip={intl.formatMessage({
                        id: 'Correspond with the customer',
                      })}
                      data-for="correspond-tooltip"
                    >
                      {clientData.email ? clientData.email : 'NA'}
                    </span>
                  </p>
                  <ToolTip id="correspond-tooltip" place="right" />
                </div>
              </div>
            </div>
            <div className="quaterly-right">
              <div className="user-address-fld-inn">
                <div className="ofc-no sprite-icon-before">
                  <p>
                    {intl.formatMessage({ id: 'Office Number' })}:
                    <span>
                      {clientData.officePhone ? clientData.officePhone : 'NA'}
                    </span>
                  </p>
                </div>
                <div className="ofc-addr sprite-icon-before">
                  <p>
                    {intl.formatMessage({ id: 'Address' })}:
                    <span id="edit-job-address">
                      {`${clientData.businessName},`}
                      <br />
                      {`${address}`}
                      <br />
                      {`${cityStateZip}`}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="address-icon sprite-icon1"
              onClick={this.handleRedirectionToMaps}
            ></div>
          </div>
          <hr className="seperator" />
          <div className="job-link-date-wrap">
            <div className="job-link-date-left">
              <p className="date-head">
                <FormattedMessage
                  id="Start Date and Time"
                  defaultMessage="Start Date and Time"
                />
              </p>
              <div
                className="datepicker-wrap sprite-icon-before"
                id="select-start-date"
              >
                <SingleDatePicker
                  date={eventStartDate}
                  onChange={(value) =>
                    this.setState({
                      eventStartDate: value,
                      eventEndDate: this.getNewEndDate(value),
                    })
                  }
                />
                <label>
                  <FormattedMessage
                    id="Select Start Date"
                    defaultMessage="Select Start Date"
                  />
                </label>
              </div>
              <p className="start-time-head">
                <FormattedMessage
                  id="Select Start Time (HH:MM)"
                  defaultMessage="Select Start Time (HH:MM)"
                />
              </p>
              <div className="select-time-wrap">
                <DropDown
                  dropDownList={getHours(12)}
                  name="hours"
                  handleOnChange={this.handleTimeSelection}
                  error=""
                  value={hours}
                  className="dis-flex f-dir-column"
                  placeholder="Hours"
                />
                <DropDown
                  dropDownList={['00', '30']}
                  name="minutes"
                  handleOnChange={this.handleTimeSelection}
                  error=""
                  value={minutes}
                  className="dis-flex f-dir-column"
                  placeholder="Mins"
                />
                <DropDown
                  dropDownList={['AM', 'PM']}
                  name="timeFormat"
                  handleOnChange={this.handleTimeSelection}
                  error=""
                  value={timeFormat}
                  className="dis-flex f-dir-column"
                  placeholder="Mins"
                />
              </div>
              <CustomMultiSelectDropDown
                className="dis-flex f-dir-column posi-relative mr-top-24"
                dropDownList={
                  getTechniciansName(teamListByPermission, true)
                    ? orderBy(getTechniciansName(teamListByPermission, true), [
                        (user) => user.name.toLowerCase(),
                      ])
                    : []
                }
                name="teams"
                handleOnChange={this.handleTeamSelection}
                placeholder="Select Here"
                selectedValues={Object.keys(this.state.selectedTeamNames)}
                dropItemSelected={this.state.selectedTeamNames}
                label="Add Technicians"
                error={errors.technician}
                namesWithId={this.state.teamsWithId}
              />
              {defaultTechnicians && defaultTechnicians.length ? (
                <p className="small-text-msg">
                  {`${defaultTechnicians.join(', ')}`}
                  <FormattedMessage
                    id="has been set as a default inspector(s) for future occurence of these kind of job."
                    defaultMessage="has been set as a default inspector(s) for future occurence of these kind of job."
                  />
                </p>
              ) : null}
            </div>
            <div className="job-link-date-right">
              <p className="date-head">
                <FormattedMessage
                  id="End Date and Time"
                  defaultMessage="End Date and Time"
                />
              </p>
              <DropDown
                dropDownList={durationTypes}
                name="durationBy"
                handleOnChange={this.handleChangeDurationBy}
                error=""
                value={durationBy}
                className="dis-flex f-dir-column"
                placeholder="Select Duration By"
                label="Duration By"
              />
              <div className="select-time-wrap">
                <p className="start-time-head mr-rt-20">
                  {intl.formatMessage({ id: 'Duration(Hrs.)' })}
                </p>
                <p className="start-time-head">
                  {intl.formatMessage({ id: 'Select End Date' })}
                </p>
              </div>
              <div className="select-time-wrap joblink-date-picker-error-handling">
                <div>
                  <DropDown
                    dropDownList={getHours(24, 0.5)}
                    name="duration"
                    handleOnChange={this.handleTimeSelection}
                    error={errors.duration}
                    value={duration}
                    className="dis-flex f-dir-column"
                    placeholder="Hours"
                    isDisabled={durationBy === durationTypes[1]}
                    errorClassName="error-date-picker"
                  />
                </div>
                <div
                  className="datepicker-wrap edit-job-modal-datepicker-icon sprite-icon-before"
                  id="select-end-date"
                >
                  <span className="errorMessage">{errors.endDate}</span>
                  <SingleDatePicker
                    date={eventEndDate}
                    onChange={(value) => {
                      if (durationBy === durationTypes[1]) {
                        value.endOf('day');
                      }
                      this.setState({ eventEndDate: value }, () => {
                        if (errors.endDate) this.setErrors();
                      });
                    }}
                    disabled={durationBy === durationTypes[0]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="check-clips-wrap job-pop-clip">
            {Object.keys(selectedTeamNames).map((team) => (
              <div className="search-chip" key={team}>
                <label
                  className="cust-checkbox"
                  style={jobDetail.isRecurring ? {} : { paddingLeft: '0px' }}
                >
                  {this.state.teamsWithId[team]}
                  {jobDetail.isRecurring && (
                    <div>
                      <input
                        id="test-checkbox"
                        type="checkbox"
                        checked={this.getDefaultTechnician(team).isDefaultTech}
                        onChange={(e) =>
                          this.setTechnicians(team, true, e.target.checked)
                        }
                      />
                      <span className="checkmark"></span>
                    </div>
                  )}
                </label>
                <span
                  id="test-sprite-icon"
                  className="sprite-icon"
                  onClick={() => this.handleTeamSelection(team, false)}
                ></span>
              </div>
            ))}
          </div>
          {this.props.jobDetail.recurringInspectionDetails.tasks &&
            this.props.jobDetail.recurringInspectionDetails.tasks.length >
              0 && (
              <TaskModalBody
                task={this.props.jobDetail.recurringInspectionDetails.tasks}
                inspectionDetails={{ isFetching: false }}
                height="200px"
              />
            )}
          <div
            className={`job-id-note mr-top-24 ${this.state.noteAcc && 'open'}`}
            onClick={this.noteAccordian}
          >
            <p className="sprite-icon-before">
              <span
                data-tip={intl.formatMessage({
                  id: 'Leave notes for the tech or customer.',
                })}
                data-for="notes-joblink"
              >
                <FormattedMessage id="NOTES" defaultMessage="NOTES" />
                {totalNotes ? ` (${totalNotes})` : ''}
              </span>
            </p>
            <ToolTip id="notes-joblink" place="right" />
          </div>
          {noteAcc && (
            <NotesBodys
              iNotes={inspectorNotes}
              cNotes={customerNotes}
              handleModalNotes={this.handleAddNotes}
            />
          )}
          {checkPermission(
            subPermissions.scheduleOthersJobs,
            permissions.jobLink
          ) ||
          checkPermission(
            subPermissions.scheduleMyJobs,
            permissions.jobLink
          ) ? (
            <div className="cust-select-btns">
              <Button
                text="Delete"
                color="#B71B1B"
                onBtnClick={() => this.setState({ isDeleteModal: true })}
              />
              <Button
                text="Unassign"
                className="btn_table mr-rt-20"
                color="#282C37"
                onBtnClick={() => {
                  this.props.unassignJobAction(originalJobId);
                }}
              />
              <Button
                text="Save"
                color="#6AA14E"
                onBtnClick={this.handleSaveButton}
              />
            </div>
          ) : null}
        </span>
      </React.Fragment>
    );
  }
}

EditJobModalBody.propTypes = {
  jobId: PropTypes.number,
  clientData: PropTypes.object,
  currentEvent: PropTypes.object,
  teams: PropTypes.array,
  techniciansAssigned: PropTypes.array,
  assignJobAction: PropTypes.func,
  originalJobId: PropTypes.string,
  jobDetail: PropTypes.object,
  unassignJobAction: PropTypes.func,
  deleteJobAction: PropTypes.func,
  closeModal: PropTypes.func,
  unassignedJob: PropTypes.object,
  deletedJob: PropTypes.object,
  getJobListAction: PropTypes.func,
  getMissedJobListAction: PropTypes.func,
  history: PropTypes.object,
  latLong: PropTypes.object,
  getTeamListWithFiltersAction: PropTypes.func,
  calenderViewData: PropTypes.object,
  resourceReportCategory: PropTypes.array,
  assignedJob: PropTypes.func,
  intl: intlShape.isRequired,
  getNextMonthJobListAction: PropTypes.func,
  jobDetailIsSuccess: PropTypes.bool,
  auth: PropTypes.object,
  runCoachMarks: PropTypes.func,
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
};

const mapStateToProps = ({ jobListDetails, auth, signUpStatus }) => ({
  unassignedJob: jobListDetails.unassignedJob,
  deletedJob: jobListDetails.deletedJob,
  latLong: jobListDetails.latLong,
  calenderViewData: jobListDetails.calenderViewData,
  assignedJob: jobListDetails.assignedJob,
  auth,
  currentCoachmarkTarget: signUpStatus.currentTarget,
});

export default connect(mapStateToProps, {
  assignJobAction,
  unassignJobAction,
  deleteJobAction,
  getJobListAction,
  getMissedJobListAction,
  getTeamListWithFiltersAction,
  getNextMonthJobListAction,
  runCoachMarks,
  toggleCoachmarksVisibility,
})(withRouter(injectIntl(EditJobModalBody)));

import moment from 'moment';

export const BuglistHeaderFields = [
  {
    title: 'Bug Title',
    sorting: false,
  },
  {
    title: 'Description',
    sorting: false,
  },
  {
    title: 'Date of Reporting',
    sorting: false,
  },
  {
    title: 'Status',
    sorting: false,
  },
];

export const BugStatusClass = {
  pending: { className: 'out-stock', label: 'Pending' },
  inProgress: { className: 'in-stock', label: 'In Progress' },
  resolved: { className: 'resolved', label: 'Resolved' },
};

export const AutoRouteErrorMessage = 'Address for one or more jobs is invalid';

export const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
export const reportfilters = {
  pump: {
    label: 'Pump',
    status: false,
  },
  backflow: {
    label: 'Backflow',
    status: false,
  },

  Alarm: {
    label: 'Alarm',
    status: false,
  },
  Security: {
    label: 'Security',
    status: false,
  },
};

export const currentYear = moment().year();
export const previousYear = moment().year() - 1;
export const months = moment.months();

export const areaChartProperties = {
  legends: [{ value: currentYear, legendClass: 'red-legend-box' }],
  areas: [
    {
      type: 'linear',
      dataKey: previousYear,
      stroke: '#D7DCE0',
      fillOpacity: 0.2,
      fill: '#D7DCE0',
      activeDot: true,
      dot: { strokeWidth: 1 },
    },
    {
      type: 'linear',
      dataKey: currentYear,
      stroke: '#A51C1C',
      fillOpacity: 0.2,
      fill: '#A51C1C',
      activeDot: true,
      dot: { strokeWidth: 1 },
    },
  ],
};

export const reportsColor = {
  Alarm: '#22bec5',
  Backflow: '#c776b1',
  'Emergency Exit/Light': '#008595',
  Extinguisher: '#734fa0',
  'Fire Door': '#f79868',
  'Grease Cleaning': '#ccccff',
  'Hood System': '#ffcc66',
  Hydrant: '#6699cc',
  Monitoring: '#75cac2',
  Pump: '#3769b3',
  Security: '#947f2f',
  Sensitivity: '#773217',
  'Special Hazard': '#f85b12',
  Sprinkler: '#333399',
  'Standpipe/Hose': '#f5dd42',
  Suppression: '#429ef5',
  'Water Tank': '#42f5b0',
  Other: '#e942f5',
};

export const dueGraphProperties = {
  innerRadius: '45%',
  outerRadius: '90%',
  showLegend: true,
};

export const jobsGraphProperties = {
  innerRadius: '62%',
  outerRadius: '70%',
  showLegend: false,
};

export const PartHeaders = [
  {
    title: 'PartName',
    sorting: false,
  },
  {
    title: 'Quantity',
    sorting: false,
  },
  {
    title: 'Unit Price($)',
    sorting: false,
  },
  {
    title: ' ',
    sorting: false,
  },
];

export const LabourHeaders = [
  {
    title: 'Name',
    sorting: false,
  },
  {
    title: 'Labor Hours',
    sorting: false,
  },
  {
    title: 'Unit Price($)',
    sorting: false,
  },
  {
    title: ' ',
    sorting: false,
  },
];

export const InventoryVehicleRowHeader = [
  {
    title: 'Vehicle Number',
    sorting: false,
  },
  {
    title: 'Assigned Technician',
    sorting: false,
  },
  {
    title: 'Parts Quantity',
    sorting: false,
  },
  {
    title: 'Tools Quantity',
    sorting: false,
  },
];

export const SupplierListHeader = [
  {
    title: ' ',
    sorting: false,
    width: '9%',
  },
  {
    title: 'Suppliers Name',
    sorting: false,
    width: '19%',
  },
  {
    title: 'Email Id',
    sorting: false,
    width: '20%',
  },
  {
    title: 'Contact Number',
    sorting: false,
    width: '20%',
  },
  {
    title: 'Status',
    sorting: false,
    width: '12%',
  },
];

export const PartListHeader = [
  {
    title: 'Part Image',
    sorting: false,
  },
  {
    title: 'Part Name',
    sorting: false,
  },
  {
    title: 'Report Category',
    sorting: false,
  },
  {
    title: 'SKU',
    sorting: false,
  },
  {
    title: 'Qty/Warehouse',
    sorting: false,
  },
  {
    title: 'Qty/Vehicle',
    sorting: false,
  },
  {
    title: 'Status',
    sorting: false,
  },
];

export const AssignPartsHeader = [
  {
    title: 'Part',
    sorting: false,
  },
  {
    title: 'SKU',
    sorting: false,
  },
  {
    title: 'Quantity',
    sorting: false,
  },
  {
    title: 'Price($)',
    sorting: false,
  },
  {
    title: ' ',
    sorting: false,
  },
];

export const InventoryDetailsPartsTableHeader = [
  {
    title: 'Parts',
    sorting: false,
  },
  {
    title: 'SKU',
    sorting: false,
  },
  {
    title: 'Quantity',
    sorting: false,
  },
  {
    title: ' ',
    sorting: false,
  },
];

export const TransactionDetailsPartsTableHeader = [
  {
    title: 'Parts',
    sorting: false,
  },
  {
    title: 'SKU',
    sorting: false,
  },
  {
    title: 'Transact Quantity',
    sorting: false,
  },
  {
    title: 'Warehouse Quantity',
    sorting: false,
  },
  {
    title: 'Report Type',
    sorting: false,
  },
];

export const InventoryDetailsToolsTableHeader = [
  {
    title: 'Tools',
    sorting: false,
  },
  {
    title: 'ID.',
    sorting: false,
  },
  {
    title: 'Quantity',
    sorting: false,
  },
  {
    title: ' ',
    sorting: false,
  },
];

export const TransactionDetailsToolsTableHeader = [
  {
    title: 'Tools',
    sorting: false,
  },
  {
    title: 'ID.',
    sorting: false,
  },
  {
    title: 'Transact Quantity',
    sorting: false,
  },
  {
    title: 'WareHouse Quantity',
    sorting: false,
  },
];

export const AssignToolsHeader = [
  {
    title: 'Tool',
    sorting: false,
  },
  {
    title: 'Id Number',
    sorting: false,
  },
  {
    title: 'Quantity',
    sorting: false,
  },
  {
    title: 'Description',
    sorting: false,
  },
  {
    title: ' ',
    sorting: false,
  },
];

export const BUTTON_SUCCESS_COLOR = '#6aa14e';

export const TOOL_STATUS = [
  { name: 'Archive' },
  { name: 'In Stock' },
  { name: 'Out of Stock' },
];

export const VEHICLE_STATUS = [{ name: 'Archived' }, { name: 'UnArchived' }];

export const AutoRouting = 'AUTOROUTING';

export const SUPPLIER_STATUS = [{ name: 'Active' }, { name: 'Archive' }];

export const MAX_LIMIT_DEFAULT = 1000

export const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 5,
};

export const MAP_LAYOUTS = {
  AUTOROUTING: 'AUTOROUTING',
  TEAM_GPS: 'TEAM_GPS',
  EMPLOYEE_GPS: 'EMPLOYEE_GPS',
}

export const CURRENT_LOCATION_ICON = "img/curr-loc.png"

export const getValidationMessage = (type, variant = null) => {
  const validationMessages = {
    "required": "This field is required",
    "exists": "This '{{item}}' already exists",
    "location_exists": "This location has already been used, please provide a unique location for each system/device",
  }

  const template = validationMessages[type] || "Invalid value"
  return variant? template.replace("{{item}}", variant) : template
}

export const inventoryStepsSchema = [
  {
    title: 'step1_title',
    description: 'step1_description',
    type: 'step'
  },
  {
    title: 'step2_title',
    description: 'step2_description',
    type: 'step',
  },
  {
    title: 'note1_title',
    description: 'note1_description',
    type: 'note',
  },
  {
    title: 'step3_title',
    description: 'step3_description',
    type: 'step',
  },
  {
    title: 'step4_title',
    description: 'step4_description',
    type: 'step',
  },
];

export const deficiencyStatus = {
    needsQuoted: "Needs Quoted",
    quoteApprovalPending: "Quote Approval Pending",
    quoteRejected: "Quote Rejected",
    quoteSendToJobLink: "Quote Sent to JobLink",
    quoteApproved: "Quote Approved",
    quoteCompleted: "Quote Completed",
    woCreated: "WO Created",
    woScheduled: "WO Scheduled",
    woCompleted: "WO Completed"
} 

export const monitoringStructure =  {
  id: 'monitoringDoesThisFacilityHaveAPEARS',
  pdfSection: 'Public Emergency Alarm Reporting System',
  questionText: 'Does this facility have a Public Emergency Alarm Reporting System?',
  type: 'radio',
  nfpaReference: '27.2.2',
  options: [{ label: 'Yes' }, { label: 'No' }],
  isMemorized: true,
  children: [
    {
      id: 'monitoringPEARSAlarmSignal',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Alarm Signal?',
      type: 'radio',
      nfpaReference: '10.11',
      isMemorized: false,
      isStatus: true,
      options: [
        { label: 'Pass' },
        { label: 'Fail' },
        { label: 'N/A' },
      ],
      parentValue: ['Yes'],
      questionText_es: '¿Señal de alarma?',
    },
    {
      id: 'monitoringPEARSAlarmSignalTime',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Alarm signal time?',
      type: 'timePicker',
      nfpaReference: '10.11.1',
      isMemorized: false,
      parentValue: ['Yes'],
      questionText_es: 'Tiempo de la señal de alarma?',
    },
    {
      id: 'monitoringPEARSAlarmRestoration',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Alarm restoration?',
      type: 'radio',
      nfpaReference: '10.12.1',
      isMemorized: false,
      isStatus: true,
      options: [
        { label: 'Pass' },
        { label: 'Fail' },
        { label: 'N/A' },
      ],
      parentValue: ['Yes'],
      questionText_es: 'Restauración de alarma?',
    },
    {
      id: 'monitoringPEARSAlarmRestorationTime',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Alarm restoration time?',
      type: 'timePicker',
      nfpaReference: '26.3.8.3.5.1',
      isMemorized: false,
      parentValue: ['Yes'],
      questionText_es: 'Tiempo de restauración de alarma?',
    },
    {
      id: 'monitoringPEARSTroubleSignal',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Trouble signal?',
      type: 'radio',
      nfpaReference: '10.15.1',
      isMemorized: false,
      isStatus: true,
      options: [
        { label: 'Pass' },
        { label: 'Fail' },
        { label: 'N/A' },
      ],
      parentValue: ['Yes'],
      questionText_es: 'Señal de problemas?',
    },
    {
      id: 'monitoringPEARSTroubleSignalTime',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Trouble signal time?',
      type: 'timePicker',
      nfpaReference: '10.15.1',
      isMemorized: false,
      parentValue: ['Yes'],
      questionText_es: 'Tiempo de señal de problemas?',
    },
    {
      id: 'monitoringPEARSTroubleRestoration',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Trouble restoration?',
      type: 'radio',
      nfpaReference: '10.15.1',
      isMemorized: false,
      isStatus: true,
      options: [
        { label: 'Pass' },
        { label: 'Fail' },
        { label: 'N/A' },
      ],
      parentValue: ['Yes'],
      questionText_es: 'Restauración problemas?',
    },
    {
      id: 'monitoringPEARSTroubleRestorationTime',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Trouble restoration time?',
      type: 'timePicker',
      nfpaReference: '10.15.1',
      isMemorized: false,
      parentValue: ['Yes'],
      questionText_es: 'Tiempo de restauración problemas?',
    },
    {
      id: 'monitoringPEARSSupervisorySignal',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Supervisory signal?',
      type: 'radio',
      nfpaReference: '10.14',
      isMemorized: false,
      isStatus: true,
      options: [
        { label: 'Pass' },
        { label: 'Fail' },
        { label: 'N/A' },
      ],
      parentValue: ['Yes'],
      questionText_es: 'Señal de supervisión?',
    },
    {
      id: 'monitoringPEARSSupervisorySignalTime',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Supervisory signal time?',
      type: 'timePicker',
      nfpaReference: '10.14',
      isMemorized: false,
      parentValue: ['Yes'],
      questionText_es: 'Tiempo de la señal de supervisión?',
    },
    {
      id: 'monitoringPEARSSupervisoryRestoration',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Supervisory restoration?',
      type: 'radio',
      nfpaReference: '10.14',
      isMemorized: false,
      isStatus: true,
      options: [
        { label: 'Pass' },
        { label: 'Fail' },
        { label: 'N/A' },
      ],
      parentValue: ['Yes'],
      questionText_es: 'Restauración de supervisión?',
    },
    {
      id: 'monitoringPEARSSupervisoryRestorationTime',
      pdfSection: 'Public Emergency Alarm Reporting System',
      questionText: 'Supervisory restoration time?',
      type: 'timePicker',
      nfpaReference: '10.14',
      isMemorized: false,
      parentValue: ['Yes'],
      questionText_es: 'Tiempo de restauración de supervisión?',
    },
  ],
  questionText_es: '¿Esta instalación con un sistema de notificación de alarma de emergencia pública?',
}

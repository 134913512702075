export const EVENTS = {
  CUSTOMERS_IMPORT_CUSTOMERS_DOWNLOAD_SPREADSHEET: 'CUSTOMERS_IMPORT_CUSTOMERS_DOWNLOAD_SPREADSHEET',
  CUSTOMERS_IMPORT_CUSTOMERS_SAVE: 'CUSTOMERS_IMPORT_CUSTOMERS_SAVE',
  CUSTOMERS_ADD_CUSTOMER_CLICK: 'CUSTOMERS_ADD_CUSTOMER_CLICK',
  CUSTOMERS_ADD_CUSTOMER_SAVE: 'CUSTOMERS_ADD_CUSTOMER_SAVE',
  TEAM_ADD_MEMBER_CLICK: 'TEAM_ADD_MEMBER_CLICK',
  TEAM_ADD_MEMBER_SAVE: 'TEAM_ADD_MEMBER_SAVE',
  REPORTS_NEW_SEARCH_CUSTOMER: 'REPORTS_NEW_SEARCH_CUSTOMER',
  REPORTS_NEW_SELECT_CUSTOMER: 'REPORTS_NEW_SELECT_CUSTOMER',
  REPORTS_NEW_NEW_REPORT_NEXT: 'REPORTS_NEW_NEW_REPORT_NEXT',
  REPORTS_NEW_CLIENT_DETAIL_NEXT: 'REPORTS_NEW_CLIENT_DETAIL_NEXT',
  REPORTS_NEW_OR_COPY_NEW: 'REPORTS_NEW_OR_COPY_NEW',
  REPORTS_EDIT_START_REPORT_START: 'REPORTS_EDIT_START_REPORT_START',
  REPORTS_EDIT_CANCEL_REPORT: 'REPORTS_EDIT_CANCEL_REPORT',
  REPORTS_EDIT_CANCEL_REPORT_CONFIRMATION_YES: 'REPORTS_EDIT_CANCEL_REPORT_CONFIRMATION_YES',
  REPORTS_EDIT_CANCEL_REPORT_CONFIRMATION_NO: 'REPORTS_EDIT_CANCEL_REPORT_CONFIRMATION_NO',
  REPORTS_EDIT_SUBMIT_REPORT: 'REPORTS_EDIT_SUBMIT_REPORT',
  REPORTS_EDIT_IMPORT_FROM_LEGACY: 'REPORTS_EDIT_IMPORT_FROM_LEGACY',
  REPORTS_EDIT_ADD_DEVICE_SAVE_OR_SAVE_AND_NEW: 'REPORTS_EDIT_ADD_DEVICE_SAVE_OR_SAVE_AND_NEW',
  SIDE_MENU_OPTION: 'SIDE_MENU_OPTION',
};